.form-signin{
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    text-align:center;
}
.form-signin h1{
    line-height:2rem;
} 
.nav-bar-name{
    text-transform: capitalize;
    color:#fff;
    font-family: "Segoe UI";
    margin-right: 1rem;
}
.table-curved {
    border-collapse: separate;
}
.table-curved {
    border: solid #ccc 1px;
    border-radius: 6px;
}
.table-curved td, .table-curved th {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
.table-curved tr > *:first-child {
    border-left: 0px;
}
.table-curved tr:first-child > * {
    border-top: 0px;
}
.table{
    color:#17A2B8;
}
.table th input{
    padding:0 10px;
    width: 100%;
}
.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
